import React from 'react'
import { IncidentT } from '../../../types/incidentT'
import { MatchT } from '../../../types/matchT'

export const isHighlightedValue = (incident: IncidentT, matches?: MatchT[], nodeValue?: string) => {
  if (matches) {
    const matchFound = matches.some((match) => match.value === nodeValue)

    return matchFound ? <mark>{nodeValue}</mark> : nodeValue
  }

  return false
}

export const toUppercase = (value: string) =>
  value
    .split('_')
    .reduce((current, next) => current.concat(' ').concat(next), '')
    .toUpperCase()
